<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card>
                    <v-sheet class="text-center pa-4 background">
                        <img v-if="$vuetify.theme.dark" :src="require('../../../assets/images/logo/liz_white.svg')" alt="logo"
                             width="80px">
                        <img v-else :src="require('../../../assets/images/logo/liz.svg')" alt="logo" width="80px">
                    </v-sheet>

                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate, handleSubmit }">
                        <v-form ref="form" @submit.prevent="handleSubmit(submit)" class="text-center pa-5">
                            <VTextFieldWithValidation
                                    v-model="slug"
                                    :label="$t('views.token.input_label')"
                                    rules="required"
                                    name="slug"/>

                            <v-btn
                                    color="accent"
                                    large
                                    :loading="AppStore.app_is_loading"
                                    class="mx-auto mt-5"
                                    @click="passes(submit)">
                                {{ $t('views.token.submit_btn') }}
                            </v-btn>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import VTextFieldWithValidation from '../../../components/communs/inputs/VTextFieldWithValidation.vue';
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

export default {
    name: 'token-view',

    components: {
        VTextFieldWithValidation,
        ValidationObserver
    },

    data() {
        return {
            slug: '',
            AppStore,
        }
    },

    created() {
    },

    mounted() {

    },

    computed: {
        _() {
            return _;
        }
    },
    methods: {
        submit() {
            AppStore.app_is_loading = true;
            const isValid = this.$refs.form.validate()
            if (isValid) {
                const slug = this.slug.split('?')[0];
                let params = this.slug.split('?')[1];
                // console.log(slug)
                // console.log(params)
                params = new URLSearchParams(params);
                const entries = params.entries(); //returns an iterator of decoded [key,value] tuples
                params = this.paramsToObject(entries);
                const url = this.$route.path.toString().split("/")
                EventBus.$emit('getApp', {type: url[1], model: url[2], slug: slug, params: params, noredirect: false})
            }
        },

        paramsToObject(entries) {
            const result = {}
            for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
                result[key] = value;
            }
            return result;
        },
    }
}
</script>
